
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { ServerPen, ServerStatePen, Erg, Competitions, CompetitionsState } from 'shared/state'
import { Instance, CompetitionInstance } from 'shared/types/logBook'

import Reconnect from './Reconnect.vue'
import Spinner from './Spinner.vue'

@Component({ components: { Reconnect, Spinner } })
export default class ServerPenConn extends Vue {
	server = ServerPen
	erg = Erg

	@Prop({ default: 'en-US' }) lang!: string

	@ServerStatePen('state') serverState
	@CompetitionsState('currentInstance') currentInstance

	@Watch('lang')
	languageChange(newLang: string) {
		Vue.loadLang(newLang)
	}

	connect() {
		if (this.ws && this.serverState !== 'connected') {
			ServerPen.setWsUrl(this.ws)
		}
		ServerPen.connect().then(() => this.$emit('ready'))
	}

	beforeDestroy() {
		ServerPen.disconnect()
	}

	get ws() {
		// console.log('holding pen ', Competitions.currentInstance)
		const port = Competitions.currentInstance.holding_pen_port
		return Competitions.currentInstance.holding_pen_url + '/' + port
	}

	@Watch('currentInstance', { deep: true, immediate: true })
	instanceChanged(newVal) {
		if (newVal) {
			this.connect()
			Erg.attachToServer(ServerPen)
		}
	}
}
