import Chart from 'chart.js'

export function createChart(chartId, chartData) {
	const ctx = document.getElementById(chartId)
	return new Chart(ctx, {
		type: chartData.type,
		data: chartData.data,
		options: chartData.options,
	})
}

export function addData(chart, label, data, index?: number) {
	chart.data.labels.push(label)
	if(!index) { index = 0 }
	chart.data.datasets[index].data.push(data)
	chart.update()
}

export function removeData(chart, index?: number) {
	chart.data.labels.shift()
	if(!index) { index = 0 }
	chart.data.datasets[index].data.shift()
	chart.update()
}

export function formatBytes(a, b = 2) {
	if (0 === a) { return '0 Bytes' }
	const c = 0 > b ? 0 : b,
		d = Math.floor(Math.log(a) / Math.log(1024))
	return (
		parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
		' ' +
		['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
	)
}
