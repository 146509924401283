import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus,
	RaceDefinition,
	RaceState,
	RemoteErgStatusMsg,
	AthleteStatus, ErgStatus, defaultAthleteStatus
} from 'shared/types/erg'

import { config } from 'display/config'

import { makeComputedBinding } from 'shared/util'
import deepClone from 'lodash'
import { RemoteControlPen } from './RemoteControlPen'
import { bus } from 'shared/state/Bus'
import { ErgState } from 'shared/state/Erg'

@Component
class RemoteErgModulePen extends Vue {
	@ErgState() status!: RaceState
	state: RemoteErgRaceState = 'ready'
	raceFiles: any = []
	resultsFiles: any = null
	ergMap: any = null
	ergStatus: any = null
	statusSummary: RemoteErgStatusMsg[] = []
	race: RaceDefinition = {
		name_long: '',
		name_short: '',
		event_name: '',
		duration: 2000,
		duration_type: 'meters',
		race_type: 'individual',
		time_cap: 0,
		split_value: 0,
		boats: [],
		c2_race_id: 0
	}
	errorMsg: string = ''
	_athlete_status_sent_timeout
	preventPolling = false
	version = ''

	public athleteStatus: AthleteStatus[]  = []


	public setStatus(s: RemoteErgRaceStatus) {
		if (!Object.values(remoteErgRaceStates).includes(s.state)) {
			throw new Error('Invalid state: ' + s.state)
		}
		this.state = s.state

		switch (s.state) {
			case 'error':
				this.errorMsg += s.detail + '. '
				break
			case 'sent_version':
				this.version = s.detail
				break
			case 'sent_races':
				this.raceFiles = s.detail.races
				break
			case 'loaded_erg_map':
				this.ergMap = s.detail
				break
			case 'sent_results':
				this.resultsFiles = s.detail
				break
			case 'status_summary_sent':
				this.statusSummary = s.detail.status
				break
			case 'loaded_race':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				break
			case 'erg_status_sent':
				this.ergStatus = s.detail.erg_status
				break
			case 'cleared_erg_map':
				this.ergStatus = []
				break
			case 'saved_loaded':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				break
			case 'discover_done':
				this.$emit('discover_done', s.detail)  // 'Should be "ok"
				break
			case 'athlete_status_sent':
				let _athleteStatus: AthleteStatus[]  = []
				// const _status: any = {}
				//  if(s.detail)
				// s.detail.athlete_status.forEach((a:AthleteStatus, i) => {
				// 	Object.keys(a).forEach(key => {
				// 		Vue.set(_status, key,  a[key])
				// 		if(key ==='erg_status') {
				// 			Object.keys(a.erg_status).forEach(key => {
				// 				Vue.set(_status['erg_status'], key,  a['erg_status'][key])
				// 			})
				// 		}
				// 	  })
				// 	  _athleteStatus.push(_status)

					// })
				_athleteStatus = s.detail.athlete_status
				this.athleteStatus = _athleteStatus


				config.set({ global: { athleteStatus: JSON.stringify(this.athleteStatus) } })

				this.$emit('athlete_status_sent', this.athleteStatus)

				// NOTE this may need improvements to be able to handle
				// websockets closing and missing responses
				clearTimeout(this._athlete_status_sent_timeout)
				this._athlete_status_sent_timeout = setTimeout(
					() => {
						if(!this.preventPolling) {
							RemoteControlPen.remoteErgAction('athlete_status')
						}
					},
					5000
				)
			default:
			break
		}
	}

	@Watch('status', {immediate: true, deep: true})
	statusChanged(newVal: RaceState) {
		this.preventPolling = (['ready',
		'sit ready',
		'attention',
		'race running',
		'race running - sound horn']
		.includes(newVal))

	}
}

export const RemoteErgPen = new RemoteErgModulePen()
export const RemoteErgStatePen = makeComputedBinding(RemoteErgPen)
