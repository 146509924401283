import { sprintf } from 'sprintf-js'
import date from 'date-and-time'

export function formatTime(value: number, precision = 0): string {
	if (value == null || isNaN(value)) {
		return ''
	}
	let hours = 0
	let minutes = Math.floor(value / 60)
	if (minutes > 59) {
		hours = Math.floor(minutes / 60)
		minutes = minutes % 60
	}
	const seconds = value % 60
	return hours
		? sprintf(`%'0.0i:%'02i:%'0${2 + (precision > 0 ? precision + 1 : 0)}.${precision}f`, hours, minutes, seconds)
		: sprintf(`%'0.0i:%'0${2 + (precision > 0 ? precision + 1 : 0)}.${precision}f`, minutes, seconds)
}
export function getTime(d: Date) {
	if (d) {
		const zone = d ? d.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2] : ''
		return date.format(d, 'h:mm A', false) + ' ' + zone
	}
	return ''
}

export function localAbbreviatedTimezone() {
	const d = new Date()
	return d.toLocaleTimeString('en-us', { timeZoneName: 'short' }).split(' ')[2]
}
export function localTimezone() {
	const d = new Date()
	return d.toLocaleTimeString('en-us', { timeZoneName: 'long' }).split(' ')[2]
}

  /** epochElapsedTime: returns string representing time elapsed (hours, minutes, seconds) from now
   * value is a number representing an epoch time value
   */
export function epochElapsedTime(value: number): string {
	const d = new Date(value)
	const now = new Date()
	const diff = Math.trunc(date.subtract(now, d).toMinutes())
	const days = diff > 1440 ? Math.trunc(diff / 1440) : 0
	const hours = diff > 60 ? Math.trunc((diff - days * 1440) / 60) : 0
	const minutes = Math.trunc(diff % 60)
	return zero (days, 'd') + zero(hours, 'h') + zero(minutes, 'm')
  }
function zero(val: number, unit: string) {
	const s = val === 0 ? '' : ' '
	const v = (val === 0 ? '' : val)
	const u = (val === 0 ? '' : unit)
	return v + u + s
  }
