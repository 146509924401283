import { Vue, Component } from 'vue-property-decorator'
import { makeComputedBinding } from 'shared/util'

export type ConnectionStatus = 'unconnected' | 'connected' | 'disconnected'

@Component
export class ServerModule extends Vue {
	wsUrl = 'ws://admin.ergrace.com/ws:443'
	hostname = 'ergrace.com'
	state: ConnectionStatus = 'unconnected'
	ws: WebSocket | null = null
	packetListeners: Array<((channel: string, packet: any) => void) | undefined> = []
	errors: any[] = []

	setWsUrl(wsUrl) {
		if (!wsUrl || wsUrl === 'ws://localhost:443') {
			return
		}
		try {
			const url = new URL(wsUrl)
			this.wsUrl = wsUrl
			this.hostname = url.hostname
		} catch (e) {
			console.error('Invalid websocket url received: ' + wsUrl)
		}
	}

	connect() {
		return new Promise((resolve, reject) => {
			this.state = 'unconnected'

			try {
				this.ws = new WebSocket(this.wsUrl)
			} catch (err) {
				return reject(err)
			}

			this.ws.addEventListener('open', () => {
				this.state = 'connected'
				this.$emit(this.state)
				resolve(null)
			})

			this.ws.addEventListener('close', () => {
				this.state = 'disconnected'
				this.$emit(this.state)
			})

			this.ws.addEventListener('message', (msg) => {
				try {
					const data = JSON.parse(msg.data)
					// console.log(Object.assign({}, data, {server: this.wsUrl}))

					Object.keys(data).forEach((channel) => {
						this.packetListeners
							.filter((listener) => listener)
							.forEach((listener) => listener!(channel, data[channel]))
					})
				} catch (err) {
					this.errors.push('Error message: ' + msg + ' / ' + err)
					 // console.error('invalid JSON from web socket: ', msg)
				}

			})

			this.ws.addEventListener('error', (e) => {
				this.errors.push('Error with ' + this.wsUrl)
				this.state = 'disconnected'
				this.$emit(this.state)
			})
		})
	}

	disconnect() {
		this.ws?.close()
	}

	send(message: any) {
		// document.getElementById('ergIn')!.innerText! += JSON.stringify(message)
		if (!this.ws || this.ws.readyState !== this.ws.OPEN) {
			return
		}
		this.ws.send(JSON.stringify(message))
	}

	addListener(listener: (type: string, packet: any) => void): number {
		this.packetListeners.push(listener)
		return this.packetListeners.length - 1
	}

	removeListener(id: number) {
		this.packetListeners[id] = undefined
	}
}

export const Server = new ServerModule()
export const ServerState = makeComputedBinding(Server)
