
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import { ServerModule, ServerState, ErgModule, Competitions, CompetitionsState } from 'shared/state'
import { Instance, CompetitionInstance } from 'shared/types/logBook'

import Reconnect from './Reconnect.vue'
import Spinner from './Spinner.vue'

@Component({ components: { Reconnect, Spinner } })
export default class ServerRace extends Vue {
	server = new ServerModule()
	erg = new ErgModule()

	@Prop({ default: 'en-US' }) lang!: string
	@Prop() wsUrl!: string // includes port
	@ServerState('state') serverState
	@Watch('lang')
	languageChange(newLang: string) {
		Vue.loadLang(newLang)
	}

	connect() {
		if (this.ws && this.serverState !== 'connected') {
			this.server.setWsUrl(this.ws)
		}
		this.server.connect()
		.then(() => {
			this.$emit('ready')
   this.erg.attachToServer(this.server)
		})
	}

	beforeDestroy() {
		this.server.disconnect()
		this.erg.attachToServer(this.server)
	}

	get ws() {
		return this.wsUrl
	}

	// @Watch('currentInstance', { deep: true, immediate: true })
	// instanceChanged(newVal) {
	// 	if (newVal) {
	// 		this.connect()
	// 		this.erg.attachToServer(this.server)
	// 	}
	// }
}
