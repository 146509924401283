import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import {
	RemoteErgRaceState,
	remoteErgRaceStates,
	RemoteErgRaceStatus,
	RaceDefinition,
	RemoteErgStatusMsg,
	AthleteStatus, ErgStatus, defaultAthleteStatus
} from 'shared/types/erg'

import { makeComputedBinding } from 'shared/util'
import { RemoteControlClass } from './RemoteControl'
import { bus } from './Bus'

interface ConnType {
	displayClients: number
	mobileClients: number
}

@Component
export class RemoteErgModule extends Vue {
	state: RemoteErgRaceState = 'ready'
	raceFiles: any = []
	resultsFiles: any = null
	ergMap: any = null
	ergStatus: any = null
	statusSummary: RemoteErgStatusMsg[] = []
	race: RaceDefinition = {
		name_long: '',
		name_short: '',
		event_name: '',
		duration: 2000,
		duration_type: 'meters',
		race_type: 'individual',
		time_cap: 0,
		split_value: 0,
		boats: [],
		c2_race_id: 0
	}
	errorMsg: string = ''
	messages: string = ''
	connections: ConnType | null = { mobileClients: 0, displayClients: 0 }
	version = ''

	public get ErgVersion() {
		const s = this.version.match(/[ErgRace ][\d\.]+/g)
		return s ? s[0].replace(' ', '') : ''
	}

	public get HPVersion() {
		const s = this.version.match(/[Vv][\d\.]+/g)
		return s ? s[0].replace('V', '') : ''
	}

	public athleteStatus: AthleteStatus[]  = []

	_athlete_status_sent_timeout
	public addMessage(message: string) {
		// log any messages and bind to the message value
		this.messages += message + '\n'
	}
	public clearMessage() {
		this.messages = ''
		this.errorMsg = ''
	}

	public setStatus(s: RemoteErgRaceStatus) { // console.log('-->state received: ', s.state)

		if (!Object.values(remoteErgRaceStates).includes(s.state)) {
			throw new Error('Invalid state: ' + s.state)
		}
		this.state = s.state

		switch (s.state) {
			case 'error':
				this.errorMsg += JSON.stringify(s.detail, null, 4)
				this.$emit('error', s.detail)
				break
			case 'sent_connections':
				// fix because response is not in proper JSON format
				const c = s.detail.toString()
				.replace('Remote Config/Display Clients', '{"displayClients"')
				.replace('Mobile Clients', ', "mobileClients"') + '}'
				try {
					this.connections = JSON.parse(c)
				} catch {
					this.connections = { mobileClients: 0, displayClients: 0 }
				}
				break
			case 'sent_version':
				this.version = s.detail
				break
			case 'sent_races':
				this.raceFiles = s.detail.races
				break
			case 'loaded_erg_map':
				this.ergMap = s.detail
				break
			case 'sent_results':
				this.resultsFiles = s.detail
				break
			case 'status_summary_sent':
				this.statusSummary = s.detail.status
				break
			case 'loaded_race':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				break
			case 'erg_status_sent':
				this.ergStatus = s.detail.erg_status
				break
			case 'cleared_erg_map':
				this.ergStatus = []
				break
			case 'prepare-syncRace':
				break
			case 'saved_loaded':
				if (s.detail && s.detail.race_definition) {
					this.race = s.detail.race_definition
				}
				this.$emit('athlete_status_sent', this.athleteStatus)
				break
			case 'discover_done':
				this.$emit('discover_done', s.detail)  // 'Should be "ok"
				if(s.detail !== 'ok') { this.$emit('error', s.detail) }
				break
			case 'holding_pen_sent':
				this.$emit('holding_pen_sent')
				break
			case 'athlete_status_sent':
			this.athleteStatus = s.detail.athlete_status
			this.$emit('athlete_status_sent', this.athleteStatus)

			clearTimeout(this._athlete_status_sent_timeout)
			// this._athlete_status_sent_timeout = setTimeout(
			// 		() => this.RemoteControl.remoteErgAction('athlete_status'),
			// 		2000
			// 	)
			break
		}

	}
}
